export const mainMenu = {
    displayMenu: {
        default: {
            level0: [
                /* concours to activate if there is some one{
                    name: 'contests',
                    onlyForLocalizations: ['fr'],
                },*/
                'achievement',
                'onlyModels',
                {
                    'name': 'followed-tab',
                    'translate': {
                        'parent': {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title'
                        },
                    },
                    icon: 'assignation',
                },
                'gamification',
                {
                    name: 'how-to',
                    onlyForLocalizations: ['fr'],
                },
                'access-future-engineer',

                {
                    name: 'contact-us',
                    class: ['menu-break'],
                },

                'mentions-legales',
                'cgurgpd',
                'politique-confidentialite',
                'accessibility',
            ],
        },
        trainer: {
            level0: [
                'home',
               /* { concours to activate when have someone
                    name: 'contests',
                    onlyForLocalizations: ['fr'],
                },*/
                'onlyModels',
                {
                    'name': 'parameter',
                    'children': ['param-child-groups', 'param-child'],
                    'translate': {
                        'parent': {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management'
                        },
                        'childs': {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes'
                            }
                        }
                    }
                },
                {
                    'name': 'followed-tab',
                    'translate': {
                        'parent': {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title'
                        }
                    }
                },
                'access-future-engineer',
                'contact-us',
                'mentions-legales',
                'cgurgpd',
                'politique-confidentialite',
                'accessibility',
            ],
        },
    },
};