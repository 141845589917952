import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {brand} from '../../../settings';

@Component({
    selector: 'app-activty-card',
    templateUrl: './activty-card.component.html',
    styleUrls: ['./activty-card.component.scss']
})
export class ActivtyCardComponent implements OnInit {
    protected readonly brand = brand;

    @Input() isActivitiesIsDone: boolean;

    @Input() title: string;

    @Input() image: string;

    @Input() subLesson: DataEntity;

    @Input() i: number;

    @Output() playEvent = new EventEmitter<{subLesson: DataEntity, i: number}>();

    constructor() {
    }

    ngOnInit(): void {
    }

    play(subLesson, i) {
        this.playEvent.emit({subLesson, i});
    }
}
