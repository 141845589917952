import {Injectable} from '@angular/core';
import {AuthorizationService} from "@modules/authorization";
import {SyncRules} from "@modules/contest/core/model/rules";
import {RolesEnum} from "shared/models/roles";

@Injectable({
    providedIn: 'root'
})
export class ContestAuthorizationService {

    constructor(
        private authorizationService: AuthorizationService,
    ) {
    }

    public activeRulesOnStartup(): void {
        this.authorizationService.removeRule(SyncRules.FillSurvey);
        this.authorizationService.removeRule(SyncRules.RegisterInContest);
        this.authorizationService.removeRule(SyncRules.RegisterGroupsInContest);
        this.authorizationService.removeRule(SyncRules.ShareCodeInContest);
        this.authorizationService.removeRule(SyncRules.CompeteInContest);
        this.authorizationService.removeRule(SyncRules.RegisterInCollectiveContest);
        this.authorizationService.removeRule(SyncRules.RegisterInIndividualContest);

        this.authorizationService.addRoleRule(SyncRules.FillSurvey, [RolesEnum.learner]);
        this.authorizationService.addRoleRule(SyncRules.RegisterInContest, [RolesEnum.trainer, RolesEnum.learner]);
        this.authorizationService.addRoleRule(SyncRules.RegisterInCollectiveContest, [RolesEnum.trainer]);
        this.authorizationService.addRoleRule(SyncRules.RegisterInIndividualContest, [RolesEnum.learner]);
        this.authorizationService.addRoleRule(SyncRules.RegisterGroupsInContest, [RolesEnum.trainer]);
        this.authorizationService.addRoleRule(SyncRules.ShareCodeInContest, [RolesEnum.trainer]);
        this.authorizationService.addRoleRule(SyncRules.CompeteInContest, [RolesEnum.learner]);

    }
}
