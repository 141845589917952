import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {OPTIONAL_EMAIL_DOMAIN} from '@modules/account-management/core/account-management-provider.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-newsletter-form',
    templateUrl: './newsletter-form.component.html',
    styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent {
    public formGroup: FormGroup;
    public isSubmitting = false;
    public displayGenericError = false;

    constructor(
        private dialogRef: MatDialogRef<NewsletterFormComponent>,
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private translate: TranslateService,
    ) {
        let email = this.profileService.userInformation.get('email');
        email = email.indexOf(OPTIONAL_EMAIL_DOMAIN) < 0 ? email : '';

        this.formGroup = this.formBuilder.group({
            email: [email, [Validators.required, Validators.email]],
            newsletter: [false, Validators.requiredTrue],
        });
    }

    public getUserLanguage(): string {
        return this.translate.currentLang;
    }

    public onSubmit(): void {
        this.isSubmitting = true;
        let userData = {
            email: this.formGroup.get('email').value,
            newsletter: this.formGroup.get('newsletter').value,
            language: this.getUserLanguage()
        };

        this.profileService.editUser(userData)
            .subscribe((isSuccess: boolean) => {
                this.isSubmitting = false;
                if (isSuccess) {
                    this.close();
                } else {
                    this.displayGenericError = true;
                }
            });
    }

    suscribeToNewsLetter($event: any): void {
        $event.preventDefault();
        $event.stopPropagation();
        const currentState = this.formGroup.get('newsletter').value;
        this.formGroup.get('newsletter').setValue(!currentState);
    }

    public close(): void {
        this.dialogRef.close();
    }
}
