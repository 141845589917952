import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {MediaService, OnlyImageFileMimes} from 'shared/media/media.service';
import {FileData} from 'shared/models';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {

    @Input() public image?: FileData<OnlyImageFileMimes> = null;
    @Output() public imageChange = new EventEmitter<FileData<OnlyImageFileMimes>>();
    @Output() public imageSoftDelete = new EventEmitter<FileData<OnlyImageFileMimes>>();

    private mediaService = inject(MediaService);

    public acceptedTypes = this.mediaService.getMimeTypes('image').join(', ');
    public uploadFile: (file: File) => Observable<FileData<OnlyImageFileMimes>> = this.mediaService.uploadImage.bind(this.mediaService);

    public handleFileUploadSuccess(uploadedFile: FileData<OnlyImageFileMimes>): void {
        this.imageChange.emit(uploadedFile);
        this.image = uploadedFile;
    }

    public handleFileUploadSoftDelete($event: FileData<OnlyImageFileMimes>): void {
        this.imageSoftDelete.emit($event);
        this.image = null;
    }
}
