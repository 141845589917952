import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {
    AccessibilityHelp,
    Autoformat,
    Autosave,
    Bold,
    ClassicEditor,
    type EditorConfig,
    Essentials,
    Italic,
    List,
    Paragraph,
    PasteFromOffice,
    SelectAll, Undo, WordCount
} from 'ckeditor5';

@Component({
    selector: 'app-wysiwyg',
    templateUrl: './wysiwyg.component.html',
    styleUrls: ['./wysiwyg.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WysiwygComponent implements OnInit, AfterViewInit {
    @Input() public content: string;
    @Input() public disabled = false;
    @Input() public required = false;
    @Output() public contentChange = new EventEmitter<SafeHtml>();
    @Output() wordCountUpdate = new EventEmitter<{ words: number, characters: number }>();

    private changeDetector = inject(ChangeDetectorRef);
    private sanitizer = inject(DomSanitizer);

    public isLayoutReady = false;
    public config: EditorConfig = {}; // CKEditor configuration

    protected readonly Editor = ClassicEditor;

    ngOnInit(): void {
        this.content = this.content || '';
    }

    ngAfterViewInit(): void {
        this.config = {
            toolbar: {
                items: ['undo', 'redo', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList'],
                shouldNotGroupWhenFull: false
            },
            plugins: [
                AccessibilityHelp,
                Autoformat,
                Autosave,
                Bold,
                Essentials,
                Italic,
                List,
                Paragraph,
                PasteFromOffice,
                SelectAll,
                Undo,
                WordCount,
            ],
            language: 'fr',
            placeholder: 'Type or paste your content here!',
            wordCount: {
                onUpdate: stats => {
                    this.wordCountUpdate.emit(stats)
                }
            }
        };

        this.isLayoutReady = true;
        this.changeDetector.detectChanges();
    }

    onContentChange(event: ChangeEvent): void {
        const sanitizedContent: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(event.editor.getData());
        this.contentChange.emit(sanitizedContent);
    }
}
