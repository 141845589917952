import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from '../../app/settings';

const settingsStructureNotification: ModelSchema = new ModelSchema({
    show: Structures.boolean(false),
});

interface NotificationSettings {
    show: boolean;
}


@Injectable({
    providedIn: 'root'
})
export class GlobalConfigurationService {
    private readonly privateSettings: any;

    constructor() {
        this.privateSettings = settingsStructureNotification.filterModel(modulesSettings.notification) as NotificationSettings;
    }

    public get isNotificationEnabled(): boolean {
        return this.privateSettings.show;
    }
}
