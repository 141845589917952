import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    FuseGroupsFormDialogComponent,
    FuseGroupsFormDialogData,
    FuseGroupsFormDialogDataItem,
} from '../groups-form/groups-form.component';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {DataSource} from '@angular/cdk/collections';
import {fuseAnimations} from 'fuse-core/animations';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Observable, Subject} from 'rxjs';
import {JoinGroupComponent} from '@modules/groups-management/core/join-group/join-group.component';
import {UntypedFormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
    Group,
    Workgroup,
} from '@modules/groups-management/core/definitions';
import {
    debounceTime,
    map,
    startWith,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {GroupsCustomActionComponent} from '../groups-custom-action/groups-custom-action.component';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {UsersImportComponent} from '@modules/groups-management/core/users-import/users-import.component';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {Trainer} from '@modules/groups-management/core/models/trainer';
import {isArray} from 'lodash-es';
import {GroupManagementConfigurationService} from '../../services/group-management-configuration.service';
import {InstitutionGroupService} from '@modules/groups-management';
import {Router} from '@angular/router';
import {GroupsListingOptions} from "@modules/groups-management/core/models/groups-listing-options";

@Component({
    selector: 'fuse-groups-list',
    templateUrl: './groups-list.component.html',
    styleUrls: ['./groups-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FuseGroupsListComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;
    @ViewChild('groupsAssociateChipInput') groupsAssociateChipInput: ElementRef;
    @ViewChild('workgroupsAssociateChipInput')
    workgroupsAssociateChipInput: ElementRef;
    @Input() displayedColumns: string[];
    @Input() dataSource: DataSource<FuseGroupsFormDialogDataItem>;
    @Input() editEntitySettings: FuseGroupsFormDialogData;
    @Input() joinLearner: FuseGroupsFormDialogData; // to join a learner to a group or a workgroup
    @Input() archiveEntitySettings: FuseGroupsFormDialogData;
    @Input() dearchiveEntitySettings: FuseGroupsFormDialogData;
    @Input() activateMetacognitionEntitySettings: FuseGroupsFormDialogData;
    @Input() deactivateMetacognitionEntitySettings: FuseGroupsFormDialogData;
    @Input() deleteEntitySettings: FuseGroupsFormDialogData;
    @Input() customActions: FuseGroupsFormDialogData[];
    @Input() options: GroupsListingOptions = {};
    @Input() addEntity?: FuseGroupsFormDialogData;
    @Input() showGlobalAddButton = true; // adding element by global button or inline
    @Input() isAddingInlineLearner = false;
    @Input() isAddingInlineLearnerRowAddButton = true;
    @Input() groupNameClassroomSelected = ''; // use to force filter on a classroom
    @Input() groupNameSelected = ''; // use to force filter on a group
    @Input() context: string; // use to force filter on a group
    @Input() isAddingLearnerFromGroupOrWorkgroup = false;
    @Input() nicknameContextAllowed = false; // displayFilter setting is used for groups learners workgroups trainer pseudo filter is allowed only for trainer and learner
    @Input() displayedFilters: string[] = []; // filter to show will change in regard of who call it
    entities: any;
    checkboxes: {} = [];
    public defaultArchive = false;
    public collapsibleFields: string[] = [];
    public filtersExpanded = true; // default not collapsed
    public isFiltersCollapsable = false;
    dialogRef: any;
    Object = Object;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    joinGroupDialogRef: MatDialogRef<JoinGroupComponent>;
    selectAll: any;
    type = '';
    archived: boolean;
    metacognitionActive: boolean;
    groupsChips: string[] = [];
    groupsCtrl = new UntypedFormControl();
    groupsAddOnBlur = false;
    groupsSeparatorKeysCodes = [ENTER, COMMA];
    groupsSelectable = true;
    groupsRemovable = true;
    groupsFilteredChips: Observable<any[]>;
    groupsAllChips = [];
    workgroupsChips = []; // array of workgroup Chips selected
    workgroupsCtrl = new UntypedFormControl();
    workgroupsAddOnBlur = false;
    workgroupsSeparatorKeysCodes = [ENTER, COMMA];
    workgroupsSelectable = true;
    workgroupsRemovable = true;
    workgroupsFilteredChips: Observable<any[]>;
    workgroupsAllChips = [];
    learnersList = [];
    trainersList: Trainer[] = [];
    renderedData = {};
    public canArchive = false;
    deleteLearner = false;
    deleteLearners = false;
    currentYearId: number;
    public videoData: DataEntity;
    public workgroupnameFilter = '';
    public groupnameFilter = '';
    public nicknameCtrl: UntypedFormControl = new UntypedFormControl('');
    public groupsAssociateCtrl = new UntypedFormControl();
    public groupsAssociateChips = [];
    public groupsAssociateAllChips = [];
    public groupsAssociateFilteredChips: Observable<any[]>;
    public groupsAssociateSeparatorKeysCodes = [ENTER, COMMA];
    public workgroupsAssociateCtrl = new UntypedFormControl();
    public workgroupsAssociateChips = [];
    public workgroupsAssociateAllChips = [];
    public workgroupsAssociateFilteredChips: Observable<any[]>;
    public workgroupsAssociateSeparatorKeysCodes = [ENTER, COMMA];
    public showMultiSelectionAction: boolean;
    public isExportEnabled = true;
    private unsubscribeInTakeUntil = new Subject<void>();
    private editingRow = -1;
    private addingLearnerRow = -2; // default value is -2 because we dynamicly inject row in array

    constructor(
        public groupsManagementService: GroupsManagementService,
        public authService: AuthenticationService,
        public dialog: MatDialog,
        private translate: TranslateService,
        public communicationCenter: CommunicationCenterService,
        private groupService: GroupService,
        private config: GroupManagementConfigurationService,
        private institutionGroupService: InstitutionGroupService,
        private router: Router,
    ) {
    }

    public get canBeJoined(): boolean {
        return !!this.groupsManagementService.settings.canBeJoined.group;
    }

    get groups(): Group[] {
        if (
            this.dataSource['learnerService'] &&
            this.dataSource['learnerService'].groupsList
        ) {
            return this.dataSource['learnerService'].groupsList;
        }

        return [];
    }

    get workgroups(): Workgroup[] {
        if (
            this.dataSource['learnerService'] &&
            this.dataSource['learnerService'].workgroupsList
        ) {
            return this.dataSource['learnerService'].workgroupsList;
        }

        return [];
    }

    get learners(): Workgroup[] {
        if (
            this.dataSource['learnerService'] &&
            this.dataSource['learnerService'].learnersList
        ) {
            return this.dataSource['learnerService'].learnersList;
        }

        return [];
    }

    get trainers(): Trainer[] {
        if (
            this.dataSource['trainerService'] &&
            this.dataSource['trainerService'].trainersList
        ) {
            return this.dataSource['trainerService'].trainersList;
        }

        return [];
    }

    /**
     * check if data are ready
     */
    get empty(): boolean {
        if (this.type === 'learner') {
            return (
                this.dataSource['learnerService'].onLearnersChanged &&
                this.dataSource['learnerService'].onLearnersChanged.value &&
                !this.dataSource['learnerService'].onLearnersChanged.value.length
            );
        }
        if (this.type === 'trainer') {
            return (
                this.dataSource['trainerService'].onTrainersChanged &&
                this.dataSource['trainerService'].onTrainersChanged.value &&
                !this.dataSource['trainerService'].onTrainersChanged.value.length
            );
        }
        return (
            this.dataSource &&
            this.dataSource['data'] &&
            !this.dataSource['data'].value.length
        );
    }

    public get align(): string {
        if (!this.empty) {
            return 'start';
        }
        return 'center';
    }

    public get isImportLearnerEnabled(): boolean {
        return this.groupsManagementService.settings.importInClasses;
    }

    isEditingRow = (index, item) => index === this.editingRow; // check if row to show is editing row use in html when condition

    isAddingLearnerRow = (index, item) => index === this.addingLearnerRow + 1; // check if row to show is adding learner row use in html when condition

    ngOnInit(): void {
        this.setShowMultiSelectionAction();

        this.canArchive = this.config.isArchiveAvailable();

        // if groupNameClassroomSelected or groupNameSelected exist whe cannot delete learner beacause
        // we are in a list of learner open outside of a group or workgroup
        if (
            this.config.isDeleteLearnerAvailable() &&
            !this.groupNameClassroomSelected &&
            !this.groupNameSelected
        ) {
            // delete learner one by one is allowed
            this.deleteLearner = true;
        }

        if (this.config.isDeleteLearnerListAvailable()) {
            // delete more than one learners by select is allowed
            this.deleteLearners = true;
        }

        this.isExportEnabled = this.config.isLearnersListExportEnabled().includes(this.authService.accessLevel);

        if (this.dataSource['groupsService']) {
            this.type = 'class';
            this.defaultArchive = this.dataSource['groupsService'].archiveMode
                ? this.dataSource['groupsService'].archiveMode
                : false;
            this.dataSource['groupsService'].onGroupsChanged
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }
        if (this.dataSource['workGroupService']) {
            this.type = 'group';
            this.defaultArchive = this.dataSource['workGroupService'].archiveMode
                ? this.dataSource['workGroupService'].archiveMode
                : false;
            this.dataSource['workGroupService'].onWorkgroupsChanged
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }

        if (this.dataSource['learnerService']) {
            this.type = 'learner';
            this.defaultArchive = this.dataSource['learnerService'].archiveMode
                ? this.dataSource['learnerService'].archiveMode
                : false;
            this.dataSource['learnerService'].filterLearnerToshow(
                this.groupNameClassroomSelected,
                this.groupNameSelected
            );
            this.dataSource['learnerService'].onLearnersChanged
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities: DataEntity[]) => {
                    this.displayedColumns = this.groupsManagementService.settings.learner
                        .columns[this.authService.accessLevel]
                        ? this.groupsManagementService.settings.learner.columns[
                            this.authService.accessLevel
                            ]
                        : this.groupsManagementService.settings.learner.columns['default'];

                    if (this.context !== 'LearnerComponent' && this.groupsManagementService.settings.learner.columns['externalSource']) {
                        this.displayedColumns = this.groupsManagementService.settings.learner.columns['externalSource'];
                    }

                    this.reset(entities);
                    if (
                        this.isAddingInlineLearner &&
                        this.groupNameClassroomSelected &&
                        this.groupNameClassroomSelected !== ''
                    ) {
                        this.entities.filter((learner) =>
                            learner.groups.includes(this.groupNameClassroomSelected)
                        );
                    }
                    if (
                        this.isAddingInlineLearner &&
                        this.groupNameSelected &&
                        this.groupNameSelected !== ''
                    ) {
                        this.entities.filter((learner) =>
                            learner.groups.includes(this.groupNameSelected)
                        );
                    }

                    this.groupsAllChips = this.groups.filter((g) => !g.archived);
                    this.workgroupsAllChips = this.workgroups.filter((g) => !g.archived);

                    this.groupsAssociateAllChips = this.groups;
                    this.workgroupsAssociateAllChips = this.workgroups;

                    this.learnersList = this.learners;

                    this.groupsFilteredChips = this.groupsCtrl.valueChanges.pipe(
                        startWith(null),
                        map((chip: string | null) =>
                            chip ? this.filter(chip, 'groups') : this.groupsAllChips.slice()
                        )
                    );

                    this.workgroupsFilteredChips = this.workgroupsCtrl.valueChanges.pipe(
                        startWith(null),
                        map((chip: string | null) =>
                            chip
                                ? this.filter(chip, 'workgroups')
                                : this.workgroupsAllChips.slice()
                        )
                    );

                    this.groupsAssociateFilteredChips =
                        this.groupsAssociateCtrl.valueChanges.pipe(
                            startWith(null),
                            map((chip: string | null) =>
                                chip
                                    ? this.filterAssociateGroup(chip)
                                    : this.groupsAssociateAllChips.slice()
                            )
                        );

                    this.workgroupsAssociateFilteredChips =
                        this.workgroupsAssociateCtrl.valueChanges.pipe(
                            startWith(null),
                            map((chip: string | null) =>
                                chip
                                    ? this.filterAssociateWorkgroup(chip)
                                    : this.workgroupsAssociateAllChips.slice()
                            )
                        );

                    this.nicknameCtrl.valueChanges
                        .pipe(
                            debounceTime(1000),
                            tap((val) => this.filteredLearner(val, 'nickname'))
                            // filter by nickname
                        )
                        .subscribe();
                });
            this.collapsibleFields =
                this.groupsManagementService.settings.collapsibleFields;
            this.isFiltersCollapsable = this.collapsibleFields.length > 1;
        }

        if (this.dataSource['trainerService']) {
            this.type = 'trainer';
            this.defaultArchive = this.dataSource['trainerService'].archiveMode
                ? this.dataSource['trainerService'].archiveMode
                : false;
            this.dataSource['trainerService'].onTrainersChanged
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                    this.nicknameCtrl.valueChanges
                        .pipe(
                            debounceTime(1000),
                            tap((val) => this.filteredTrainer(val))
                            // filter trainers by nickname
                        )
                        .subscribe();
                });
        }

        if (this.dataSource['institutionGroupService']) {
            this.type = 'institution';
            this.defaultArchive = this.dataSource['institutionGroupService']
                .archiveMode
                ? this.dataSource['institutionGroupService'].archiveMode
                : false;

            this.dataSource['institutionGroupService'].getUserInstitutions();
            this.dataSource['institutionGroupService'].onInstitutionGroupsChanged
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }

        if (
            !(
                (this.deleteEntitySettings && this.deleteLearners) ||
                this.dearchiveEntitySettings
            ) &&
            !(
                (this.displayedFilters.includes('workgroupAssociate') ||
                    this.displayedFilters.includes('groupAssociate'))
            )
        ) {
            const index = this.displayedColumns.indexOf('checkbox');
            if (index > -1) {
                this.displayedColumns.splice(index, 1);
            }
        }

        if (
            this.showExpandableRowButton() &&
            this.authService.accessLevel !== 'learner'
        ) {
            if (this.displayedColumns.indexOf('expandRow') === -1) {
                this.displayedColumns.splice(
                    this.displayedColumns.length - 1,
                    0,
                    'expandRow'
                );
            }
        }
    }

    ngOnDestroy(): any {
        this.groupnameFilter = '';
        this.workgroupnameFilter = '';
        this.removeAddedRowFromComponentIfExist();
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    reset(entities): void {
        this.entities = entities;
        this.checkboxes = {};
        if (entities) {
            for (const entity of entities) {
                this.checkboxes[entity.id] = false;
            }
            this.selectAll = false;
        }
    }

    public isSelectionActionIsEnabled(): boolean {
        return Object.keys(this.checkboxes).some(
            (k) => this.checkboxes[k] === true
        );
    }

    public getCustomActionsKeyToShow(entity): any[] {
        const keys = [];
        for (const key in this.customActions) {
            const rules = this.customActions[key].data.rules || {};
            if (!rules['needSso'] || rules['needSso'] === entity.sso) {
                keys.push(key);
            }
        }
        return keys;
    }

    updateCheck(event): void {
        this.selectAll = event.checked;
        for (const entity of this.entities) {
            this.checkboxes[entity.id] = event.checked;
        }
    }

    applyFilters(event): void {
        this.archived = event.value;
        this.metacognitionActive = event.value;
        this.dataSource['groupService'].filterArchived(event.value);
    }

    applyFilterSchoolYear(event): void {
        if (this.dataSource['groupService']) {
            this.dataSource['groupService'].filterSchoolYear(event.value);
        }
        if (this.dataSource['workgroupService']) {
            this.dataSource['workgroupService'].filterSchoolYear(event.value);
        }
        if (this.dataSource['learnerService']) {
            this.dataSource['learnerService'].filterSchoolYear(event.value);
        }
    }

    filterSelectedEntities(): any[] {
        const selectedEntities = [];
        for (const id in this.checkboxes) {
            if (this.checkboxes[id]) {
                selectedEntities.push(
                    ...this.entities.filter((entity) => entity.id.toString() === id)
                );
                this.checkboxes[id] = false;
            }
        }

        return selectedEntities;
    }

    archiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();
        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.archive',
                bodyDialog: 'groups-management.sure_archive_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_archive_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.archiveEntitySettings.callbackWithMultiple(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    dearchiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.unarchive',
                bodyDialog: 'groups-management.sure_dearchive_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_dearchive_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.dearchiveEntitySettings.callbackWithMultiple(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    activateMetacognitionListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();
        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.metacognition',
                bodyDialog:
                    'groups-management.sure_activate_metacognition_' +
                    [this.type] +
                    '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog =
                    'groups-management.sure_activate_metacognition_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.activateMetacognitionEntitySettings.callbackWithMultiple(
                        selectedEntities
                    );
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    deactivateMetacognitionListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.metacognition',
                bodyDialog:
                    'groups-management.sure_deactivate_metacognition_' +
                    [this.type] +
                    '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog =
                    'groups-management.sure_deactivate_metacognition_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.deactivateMetacognitionEntitySettings.callbackWithMultiple(
                        selectedEntities
                    );
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    deleteListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.delete',
                bodyDialog: 'groups-management.sure_remove_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_remove_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.launchFilter();
                    this.deleteEntitySettings.callbackWithMultiple(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    /**
     * edit data group user or classroom open modal or edit inline in regard of settings
     * @param entity : Object : can contain user workgroup or learner data
     * @param index : row selected
     */
    public editEntity(
        entity: FuseGroupsFormDialogDataItem,
        index: number,
        clickRow = false
    ): void {
        this.groupnameFilter = '';
        this.workgroupnameFilter = '';
        index = this.removeAddRowContainerIfExist(index);

        if (clickRow && !this.showGlobalAddButton && this.options.expandRow()) {
            this.editingRow = -1;
            if (entity.username) {
                // learner list doesn't expand anything
                return;
            }
            this.addRowWithLearnerComponent(entity, index);
            this.forceRefreshMatTable();
        } else if (this.editEntitySettings.isAuthorized(entity)) {
            if (!this.showGlobalAddButton) {
                // we edit a row after click edit in menu
                this.addingLearnerRow = -2; // reset adding state to default value
                this.editingRow = index;
                this.forceRefreshMatTable();
            } else {
                this.openEditModal(entity);
            }
        }
    }

    archiveEntity(entity): void {
        for (const field in this.archiveEntitySettings.data) {
            this.translate
                .get(this.archiveEntitySettings.data[field])
                .subscribe(
                    (translation: string) =>
                        (this.archiveEntitySettings.data[field] = translation)
                );
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.archiveEntitySettings.data,
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.archiveEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    deArchiveEntity(entity): void {
        for (const field in this.dearchiveEntitySettings.data) {
            this.translate
                .get(this.dearchiveEntitySettings.data[field])
                .subscribe(
                    (translation: string) =>
                        (this.dearchiveEntitySettings.data[field] = translation)
                );
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.dearchiveEntitySettings.data,
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.dearchiveEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    activateMetacognitionEntity(entity): void {
        for (const field in this.activateMetacognitionEntitySettings.data) {
            this.translate
                .get(this.activateMetacognitionEntitySettings.data[field])
                .subscribe(
                    (translation: string) =>
                        (this.activateMetacognitionEntitySettings.data[field] = translation)
                );
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.activateMetacognitionEntitySettings.data,
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.activateMetacognitionEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    deactivateMetacognitionEntity(entity): void {
        for (const field in this.deactivateMetacognitionEntitySettings.data) {
            this.translate
                .get(this.deactivateMetacognitionEntitySettings.data[field])
                .subscribe(
                    (translation: string) =>
                        (this.deactivateMetacognitionEntitySettings.data[field] =
                            translation)
                );
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.deactivateMetacognitionEntitySettings.data,
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deactivateMetacognitionEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    execCustomActions(action, entity): void {
        action.data.item = entity;
        this.dialogRef = this.dialog.open(GroupsCustomActionComponent, {
            panelClass: action.data.styleClasses,
            data: action.data,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.launchFilter();

                action.data.callback(result);
            }
        });
    }

    deleteEntity(entity): void {
        if (
            !entity.locked ||
            (entity.locked && this.config.isDeleteInstitutionAvailable())
        ) {
            for (const field in this.deleteEntitySettings.data) {
                this.translate
                    .get(this.deleteEntitySettings.data[field])
                    .subscribe(
                        (translation: string) =>
                            (this.deleteEntitySettings.data[field] = translation)
                    );
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: this.deleteEntitySettings.data,
            });

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.launchFilter();
                    this.deleteEntitySettings.callback(entity);
                }
                this.confirmDialogRef = null;
            });
        } else {
            const data = {
                titleDialog: 'groups-management.title_remove',
                bodyDialog: 'groups-management.locked',
                labelTrueDialog: 'OK',
            };

            for (const field in data) {
                this.translate
                    .get(data[field])
                    .subscribe((translation: string) => (data[field] = translation));
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data,
            });
        }
    }

    joinGroup(): void {
        this.joinGroupDialogRef = this.dialog.open(JoinGroupComponent, {
            data: {
                loadClass: (event) => this.dataSource['groupsService'].getClass(event),
                classExist: (event) =>
                    this.dataSource['groupsService'].classExist(event),
                joinClass: (event) => this.dataSource['groupsService'].joinGroup(event),
                removeClassFromList: (event = null) =>
                    this.dataSource['groupsService'].removeClassFromList(event),
            },
        });

        this.joinGroupDialogRef.afterClosed().subscribe((result) => {
            this.dataSource['groupsService'].removeClassFromList(result);
            this.joinGroupDialogRef = null;
        });
    }

    exportList(): void {
        const list = [];
        const headers = [
            'groups-management.csv.login',
            'groups-management.csv.groups',
            'groups-management.csv.workgroups',
        ];
        this.dataSource['learnerService'].onLearnersChanged.value.forEach(function (
            v
        ) {
            list.push({
                username: v.username,
                groups: v.groups.join(', '),
                workgroups: v.workgroups.join(', '),
            });
        });

        for (const field in headers) {
            this.translate
                .get(headers[field])
                .subscribe((translation: string) => (headers[field] = translation));
        }

        this.translate
            .get('groups-management.csv.filename')
            .subscribe((translation: string) => {
                const csv = new ngxCsv(list, translation, {
                    showLabels: true,
                    headers,
                    fieldSeparator: ';',
                });
            });
    }

    /**
     * return the year when begin the current school year in regard of the current date
     */
    public currentSchoolYearBegin(): number {
        return this.groupsManagementService.currentSchoolYearBegin();
    }

    importLearners(): void {
        let importDialogRef;
        if (
            this.groupsManagementService.settings
                .filterGroupListingsByCurrentYearByDefault
        ) {
            importDialogRef = this.dialog.open(UsersImportComponent, {
                data: {
                    groups: this.groupService.groups.filter(
                        (group) =>
                            group['schoolyear_term'].name.toString() ===
                            this.groupsManagementService
                                .currentSchoolYearBegin()
                                .toString() ||
                            !this.groupsManagementService.settings.importInClasses
                    ),
                    importInClasses:
                    this.groupsManagementService.settings.importInClasses,
                },
            });
            importDialogRef.beforeClosed().subscribe(() => {
                this.dataSource['learnerService'].loadLearners().subscribe((data) => {
                    if (this.groupsManagementService.settings.importInClasses) {
                        this.dataSource['learnerService'].filterSchoolYear(
                            this.groupsManagementService
                                .getSchoolyearTermId(
                                    +this.groupsManagementService.currentSchoolYearBegin()
                                )
                                .toString()
                        );
                    }
                });
            });
        } else {
            importDialogRef = this.dialog.open(UsersImportComponent, {
                data: {
                    groups: this.groupService.groups,
                    importInClasses:
                    this.groupsManagementService.settings.importInClasses,
                },
            });
            importDialogRef.beforeClosed().subscribe(() => {
                this.dataSource['learnerService'].loadLearners().subscribe();
            });
        }
    }

    getTranslate(val): string {
        if (val && val !== '') {
            if (val === 'trainer') {
                return 'groups-management.trainer';
            }

            if (val === 'mentor') {
                return 'groups-management.mentor';
            }
        }

        return 'generic.to-complete';
    }

    launchFilter(): void {
        if (this.dataSource['learnerService']) {
            if (this.groupsCtrl.value && this.groupsCtrl.value !== '') {
                this.filteredLearner(this.groupsCtrl.value, 'groups');
            }
            if (this.workgroupsCtrl.value && this.workgroupsCtrl.value !== '') {
                this.filteredLearner(this.groupsCtrl.value, 'workgroups');
            }
            if (this.nicknameCtrl.value && this.nicknameCtrl.value !== '') {
                this.filteredLearner(this.nicknameCtrl.value, 'nickname');
            }
        }
    }

    filteredLearner(value, type): void {
        this.learnersList = [];
        if (this.groupsCtrl.value && type === 'workgroups') {
            this.learnersList.push(
                ...this.learners.filter(
                    (learner) => learner['groups'].indexOf(this.groupsCtrl.value) !== -1
                )
            );
        }

        if (this.nicknameCtrl.value && type === 'nickname') {
            this.learnersList.push(
                ...this.learners.filter((learner) =>
                    learner['nickname'].includes(this.nicknameCtrl.value)
                )
            );
        } else {
            this.learnersList.push(
                ...this.learners.filter(
                    (learner) => learner[type].indexOf(value) !== -1
                )
            );
        }

        this.dataSource['learnerService'].onLearnersChanged.next(this.learnersList);
    }

    /**
     * filter trainer by username
     * used for manager to filter trainers
     * @param value : username
     */
    filteredTrainer(value: string): void {
        this.trainersList = [];
        if (this.nicknameCtrl.value && this.nicknameCtrl.value !== '') {
            this.trainersList.push(
                ...this.trainers.filter((trainer) =>
                    trainer['username'].includes(this.nicknameCtrl.value)
                )
            );
        } else {
            this.trainersList.push(...this.trainers);
        }

        this.dataSource['trainerService'].onTrainersChanged.next(this.trainersList);
    }

    alreadyExisting(name: string, type: string): boolean {
        return this[type].indexOf(name) !== -1;
    }

    filter(name: string, type: string): string[] {
        const allChips = type + 'AllChips';
        const chipName = type === 'groups' ? 'groupname' : 'workgroupname';

        return this[allChips].filter(
            (chip) => chip[chipName].toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }

    removeChip(chip: any, type: string): void {
        // remove chip from array of chips (group or workgroup)
        const chips = type + 'Chips';
        const index = this[chips].indexOf(chip);

        if (!chip) {
            this[chips] = [];
        } else {
            if (index >= 0) {
                this[chips].splice(index, 1);
            }

            if (type === 'workgroups') {
                if (this.groupsChips && this.groupsChips.length) {
                    this.filteredLearner(this.groupsChips[0], 'groups');
                }
            } else {
                if (this.workgroupsChips && this.workgroupsChips.length) {
                    this.filteredLearner(this.workgroupsChips[0], 'workgroups');
                }
            }

            if (
                this.groupsChips &&
                !this.groupsChips.length &&
                this.workgroupsChips &&
                !this.workgroupsChips.length
            ) {
                this.resetLearnerList();
            }
        }
        this.blurAllChipsList();
    }

    resetLearnerList(): void {
        this.learnersList = [];
        this.learnersList.push(...this.learners);
        this.dataSource['learnerService'].onLearnersChanged.next(this.learnersList);
    }

    blurAllChipsList(): void {
        this.groupsChipInput.nativeElement.blur();
        this.workgroupsChipInput.nativeElement.blur();

        if (this.groupsAssociateChipInput) {
            this.groupsAssociateChipInput.nativeElement.blur();
        }
        if (this.workgroupsAssociateChipInput) {
            this.workgroupsAssociateChipInput.nativeElement.blur();
        }
    }

    chipSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        const chips = type + 'Chips';
        const input = type + 'ChipInput';
        const chipsCtrl = type + 'Ctrl';
        this[chipsCtrl].setValue(null);
        if (!this.alreadyExisting(event.option.viewValue, chips)) {
            this.removeChip(null, type);
            this.filteredLearner(event.option.value, type);

            this[chips].push(event.option.viewValue);
            this[input].nativeElement.value = '';
        }
        this.blurAllChipsList();
    }

    /**
     * return the scholar year in regard of the begin year store in back
     */
    public schoolYears(schoolyear: any): any {
        let scholarPeriod = '';
        let emptyValue = '';
        if (schoolyear) {
            if (isArray(schoolyear)) {
                const scholarPeriods = [];
                schoolyear.forEach((element) => {
                    scholarPeriod = element + '-' + (+element + 1);
                    if (schoolyear.length > 1) {
                        emptyValue = 'no value';
                    }
                    scholarPeriods.push(
                        scholarPeriod !== '-1' ? scholarPeriod : emptyValue
                    );
                });
                return scholarPeriods.join(', ');
            } else {
                scholarPeriod = schoolyear + '-' + (+schoolyear + 1);
                return scholarPeriod !== '-1' ? scholarPeriod : emptyValue;
            }
        }
    }

    public formatLevel(level: string | any[]): string {
        if (Array.isArray(level)) {
            if (level.length > 0 && level[0] && level[0]?.label) {
                return level[0]?.label;
            }
        } else {
            const levelId = parseInt(level, 10);

            if (!isNaN(levelId)) {
                // get educational level name and return it
                const educationalLevel = this.groupService.getEducationalLevels().find((level) => +level.id === +levelId);

                if (educationalLevel) {
                    return educationalLevel.get('label');
                }
            }

            return level;
        }

        return '';
    }

    /**
     * play help video
     */
    public playHelpVideo(): void {
        const dialogConfig = new MatDialogConfig();

        this.translate
            .get('generic.help.video')
            .subscribe((translation: string) => {
                dialogConfig.data = {
                    titleDialog: translation,
                    panelClass: ['help-groups-list-dialog', 'wide-dialog'],
                };
            });

        dialogConfig.data.bodyDialog =
            '<video controls>' +
            '<source src="' +
            this.videoUrlToLaunch() +
            '" type="video/mp4">' +
            'Your browser does not support HTML5 video.' +
            '</video>';

        const dialogRef = this.dialog.open(
            FuseConfirmDialogComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                window.open('uri', '_blank');
            }
        });
    }

    /**
     * is field present in array
     **/
    public hasField(field: string): boolean {
        return this.displayedColumns.filter((f) => f === field).length > 0;
    }

    /**
     * curent edited value
     * @param entity
     */
    public editedValue(entity: any): FuseGroupsFormDialogData {
        this.editEntitySettings.data.item = entity;
        return this.editEntitySettings;
    }

    /**
     * When a child save is in progress learner will be refreshed and also mat-table
     * reset index before to avoid reopening same row in editing mode after refresh
     */
    saveInProgress(): void {
        this.editingRow = -1;
    }

    /**
     * save done or editing canceled no editing row currently to show
     */
    public resetIndexEditedRow(): void {
        this.editingRow = -1;
        this.forceRefreshMatTable();
    }

    public displayFilter(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    public shouldDisplayFilters(): boolean {
        return (
            !!this.displayedFilters &&
            this.displayedFilters.length > 0 &&
            (this.isAddingInlineLearner === undefined || !this.isAddingInlineLearner)
        );
    }

    /**
     * is row is in expandable context not learner service and manage adding learner by row add
     */
    public showExpandableRowButton(): boolean {
        const d = this.dataSource as any;
        return !this.showGlobalAddButton && !d.learnerService;
    }

    /**
     * check if the row have open a new underrow with learner list inside
     * @param index : row index
     */
    public isOpenAddingLearnerRow(index: number): boolean {
        return index === this.addingLearnerRow;
    }

    /**
     * collapse current opened row
     */
    public collapseLearnerRow($event: any): void {
        if ($event) {
            $event.stopPropagation();
        }
        this.removeAddRowContainerIfExist(this.addingLearnerRow); // remove row added to show learners
        this.addingLearnerRow = -2; // reset adding state to default value
        this.editingRow = -1; // reset edit state to default value
        this.forceRefreshMatTable();
    }

    /**
     * when adding a group and learner add row is open a row disapear (only if the add learner row is open)
     * we need to refresh the data and to reopen the add learner line after it to push it at good place
     */
    public manageAddLearnerOpenedLine(): void {
        if (this.addingLearnerRow !== -2) {
            this.removeAddRowContainerIfExist(this.addingLearnerRow); // remove row added to show learners
            this.forceRefreshMatTable();
            this.entities.splice(this.addingLearnerRow + 1, 0, 'addingStudentRow');
            this.forceRefreshMatTable();
        }
    }

    /**
     * navigate to page results with leaner id
     * @param entity
     */
    navigateToResult(entity: any): void {
        this.communicationCenter
            .getRoom(this.config.getGraphRoomId())
            .next('initSelectedLearnerFilter', entity.id);
    }

    /**
     * navigate to page results with leaner id
     * @param entity
     */
    navigateToAssignationTabResult(entity: any): void {
        this.router.navigate(['followed/tab/results'], {
            queryParams: {
                learnerId : entity.id
            }
        });
    }

    /**
     * classe selectionné pour être associé aux eleves
     * @param event
     */
    public groupToAssociateSelected(event: MatAutocompleteSelectedEvent): void {
        this.groupsAssociateCtrl.setValue(null);
        if (!this.groupsAssociateChips.includes(event.option.viewValue)) {
            this.groupsAssociateChips.push(event.option.viewValue);
            this.groupsAssociateChipInput.nativeElement.value = '';
        }
        this.blurAllChipsList();
    }

    /**
     * supprime la classse selectionné pour être associé aux eleves
     * @param chip
     */
    public removeGroupToAssociate(chip): void {
        const index = this.groupsAssociateChips.indexOf(chip);
        if (index >= 0) {
            this.groupsAssociateChips.splice(index, 1);
        }
    }

    /**
     * groupe selectionné pour être associé aux eleves
     * @param event
     */
    public workgroupToAssociateSelected(
        event: MatAutocompleteSelectedEvent
    ): void {
        this.workgroupsAssociateCtrl.setValue(null);
        if (!this.workgroupsAssociateChips.includes(event.option.viewValue)) {
            this.workgroupsAssociateChips.push(event.option.viewValue);
            this.workgroupsAssociateChipInput.nativeElement.value = '';
        }
        this.blurAllChipsList();
    }

    /**
     * supprime le groupe selectionné pour être associé aux eleves
     * @param chip
     */
    public removeWorkGroupToAssociate(chip): void {
        const index = this.workgroupsAssociateChips.indexOf(chip);
        if (index >= 0) {
            this.workgroupsAssociateChips.splice(index, 1);
        }
    }

    public associateToLearners(): void {
        const data: { [key: string]: any }[] = this.filterSelectedEntities().map(
            (learner: any) => {
                return {
                    id: learner.id,
                    groups: [...learner.groups, ...this.groupsAssociateChips],
                    workgroups: [...learner.workgroups, ...this.workgroupsAssociateChips],
                };
            }
        );
        this.editEntitySettings.callbackWithMultiple(data);
    }

    public showHideFields(): void {
        this.filtersExpanded = !this.filtersExpanded;
    }

    public hideEditionForSSO(): boolean {
        return (
            this.authService.isSSO() &&
            this.groupsManagementService.settings.hideEditSSO
            && this.context !== 'WorkgroupComponent'
        );
    }

    public canSeeMenu(entity: Group | any): boolean {
        return this.haveSomeRights(entity) && !this.hideEditionForSSO();
    }

    /**
     * return the video help url in regard of lang and place
     */
    public videoUrlToLaunch(): string {
        let url = '';
        // defensive programming if error due to setting not existing don't do anything normal if instance doesn't use this field
        try {
            if (this.videoData) {
                if (this.videoData.get('helpGroupVideo')[this.translate.currentLang]) {
                    url =
                        this.videoData.get('helpGroupVideo')[this.translate.currentLang];
                }
            }
        } catch (ex) {
            // nothing url doesn't exist from most instance just return '';
        }
        return url;
    }

    public canActiveMetacognition(): boolean {
        return this.config.isMetacognitionAvailable();
    }

    public canExpand(entity: any): boolean {
        return this.haveSomeRights(entity) || this.options?.expandRow();
    }

    public isCodeClassDisabled(): boolean | null {
        return this.institutionGroupService.isAnyInstitutionCodeClassDisabled();
    }

    public openAssignGroups(entity, assignOnly: boolean): void {
        this.editEntitySettings.assignGroups(entity, assignOnly);
    }

    public isAssignButtonInGroupListMustBeDisplayed(): boolean {
        return this.config.isAssignButtonInGroupListMustBeDisplayed();
    }

    /**
     * check if we can show the second toolbar with multiple selection action
     * @private
     */
    private setShowMultiSelectionAction(): void {
        this.showMultiSelectionAction =
            (this.authService.isAtLeastTrainer() &&
                (!!this.dearchiveEntitySettings || !!this.archiveEntitySettings)) ||
            !!this.activateMetacognitionEntitySettings ||
            !!this.deactivateMetacognitionEntitySettings ||
            this.displayFilter('groupAssociate') ||
            this.displayFilter('workgroupAssociate') ||
            (!!this.deleteEntitySettings && this.deleteLearners);
    }

    /**
     * remove row previously added to add learner component inside if exist
     */
    private removeAddedRowFromComponentIfExist(): void {
        if (this.entities) {
            const indexAddingStudentComponent =
                this.entities.indexOf('addingStudentRow');
            if (indexAddingStudentComponent !== -1) {
                this.entities.splice(indexAddingStudentComponent, 1);
            }
        }
    }

    /**
     * open a list of learners inside a row just after the group or workgroup row where user had clicked
     * we push a row inside with addingStudentRow content to push inside the component
     * @param entity : current row
     * @param index : current row where user had clicks
     */
    private addRowWithLearnerComponent(entity: any, index: number): void {
        this.workgroupnameFilter = entity.workgroupname;
        this.groupnameFilter = entity.groupname;
        this.addingLearnerRow = index;
        this.entities.splice(this.addingLearnerRow + 1, 0, 'addingStudentRow');
    }

    /**
     * to add row with learner list inside group or workgroup we push a addingStudentRow row.
     * Here whe check if it exists and there's already one we remove it and change index position to good one
     * @param index: current index of selected row
     */
    private removeAddRowContainerIfExist(index: number): number {
        const indexAddingStudentComponent =
            this.entities.indexOf('addingStudentRow');
        if (indexAddingStudentComponent !== -1) {
            if (indexAddingStudentComponent < index) {
                index = index - 1;
            }

            this.entities.splice(indexAddingStudentComponent, 1);
            this.forceRefreshMatTable();
        }
        return index;
    }

    /**
     * open modal to edit content
     * @param entity: Object of the line to edit can contain user workgroup or learner data
     */
    private openEditModal(entity: FuseGroupsFormDialogDataItem): void {
        this.editEntitySettings.data.item = entity;
        this.dialogRef = this.dialog.open(FuseGroupsFormDialogComponent, {
            panelClass: 'entity-form-dialog',
            data: this.editEntitySettings,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.launchFilter();
                this.editEntitySettings.callback(result);
            }
        });
    }

    /**
     * force refresh of mat-table to detect current selected row
     * refresh permit showing component to edit row instead of current data
     */
    private forceRefreshMatTable(): void {
        if (this.dataSource['groupsService']) {
            this.dataSource['groupsService'].onGroupsChanged.next(this.entities);
        }

        if (this.dataSource['workGroupService']) {
            this.dataSource['workGroupService'].onWorkgroupsChanged.next(
                this.entities
            );
        }

        if (this.dataSource['learnerService']) {
            this.dataSource['learnerService'].onLearnersChanged.next(this.entities);
        }
    }

    /**
     * retourne un tableau contenant les classes sans la classe actuellement sélectionné
     * @param name (nom du groupe sélectionné)
     */
    private filterAssociateGroup(name: string): string[] {
        return this.groupsAssociateAllChips.filter(
            (chip) => chip.groupname.toLowerCase() !== name.toLowerCase()
        );
    }

    /**
     * retourne un tableau contenant les groupes sans le groupe actuellement sélectionné
     * @param name (nom du groupe sélectionné)
     */
    private filterAssociateWorkgroup(name: string): string[] {
        return this.workgroupsAssociateAllChips.filter(
            (chip) => chip.workgroupname.toLowerCase() !== name.toLowerCase()
        );
    }

    private haveSomeRights(entity: any): boolean {
        return (
            (this.editEntitySettings &&
                this.editEntitySettings.isAuthorized(entity)) ||
            (this.archiveEntitySettings &&
                this.archiveEntitySettings.isAuthorized(entity)) ||
            (this.dearchiveEntitySettings &&
                this.dearchiveEntitySettings.isAuthorized(entity)) ||
            (this.deleteEntitySettings &&
                this.deleteEntitySettings.isAuthorized(entity))
        );
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo;
    }

    public get termInRegardOfGarOrNot(): string{
        return this.authService.isGAR()?'groups-management.empty_learners_gar':'groups-management.empty_learners';
    }

    public get floatLabel(): string {
        return this.config.floatLabel();
    }
}