import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, combineLatest, Subscription, BehaviorSubject} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService, OrderDirection, PaginatedCollection} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {NotificationDefinition} from '@modules/notification/core/notification-definition.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {localizedDate, localizedTime} from 'shared/utils/datetime';
import {MatDialog} from '@angular/material/dialog';
import {NotificationComponent} from '@modules/notification/core/notification.component';
import {NewNotificationInterface, NotificationEntity} from '@modules/notification/core/notification.interface';

const settingsStructure: ModelSchema = new ModelSchema({
    show: Structures.boolean(true),
    seeAll: Structures.boolean(false)
});

@Injectable()
export class NotificationsServiceCitizen {

    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});

    public hasNotifications = false;
    public unreadNotifications$: BehaviorSubject<NotificationEntity[]> = new BehaviorSubject([]);
    public readNotifications$: BehaviorSubject<NotificationEntity[]> = new BehaviorSubject([]);

    private filesIndex: { [key: number]: DataEntity } = {};

    notifications: DataEntity[] = [];
    private newNotifications: NewNotificationInterface[] = [];
    private notificationsSubscription: Subscription;
    private unreadNotificationsPaginated: PaginatedCollection;
    private readNotificationsPaginated: PaginatedCollection;

    private currentUserId: number;

    private registeredNotificationsDefinitions: { [key: string]: NotificationDefinition } = {};

    public settings: { [key: string]: any };

    public isNotifPanelOpen = false;

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private translation: TranslateService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.notification);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: DataEntity) => {
                if (user) {
                    this.currentUserId = +user.id;
                    this.postAuthentication();
                    this.getUserNotifications(+user.id, true);
                } else {
                    this.currentUserId = undefined;
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .subscribe((data: NotificationDefinition) => {
                this.registerNotification(data);
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('sendNotification')
            .subscribe((data: any) => {
                if (this.settings && this.settings.show) {
                    if (typeof data.recipient === 'string' || typeof data.recipient === 'number') {
                        this.sendNotificationToUser(data.recipient, data.type, data.content);
                    } else {
                        this.sendNotificationToUsers(data.recipient, data.type, data.content);
                    }
                }
            });
    }

    /**
     * Gets the currently logged user unread notifications count
     * @returns {number} The count
     */
    get unreadNotificationsCount(): number {
        let count = 0;
        this.notifications.forEach(entity => {
            if (entity.get('read') === false || !entity.get('read')) { // pas de champ read par defaut existe a true par contre????
                count++;
            }
        });

        return count;
    }

    /**
     * Gets the currently logged user notifications count
     * @returns {number} The count
     */
    get notificationsCount(): number {
        return this.notifications.length;
    }

    public get hadNextUnreadNotifications(): boolean {
        return this.unreadNotificationsPaginated?.paginator.hasNextPage;
    }

    public get hadNextReadNotifications(): boolean {
        return this.readNotificationsPaginated?.paginator.hasNextPage;
    }

    public get notificationsList(): NewNotificationInterface[] {
        return this.newNotifications;
    }

    private postLogout(): void {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
            this.notificationsSubscription = null;
        }
    }

    private postAuthentication(): void {
        this.loadNotificationStatus();
    }

    public openNotificationDialog() {
        this.isNotifPanelOpen = true;
        let dialogRef = this.dialog
            .open(NotificationComponent, {
                backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                panelClass: ['notification-dialog'],
                data: {},
                restoreFocus: false,
            });
        // mark all notif to read at close
        dialogRef.afterClosed().subscribe(res => {
            this.isNotifPanelOpen = false;
            this.markAsRead(this.notifications);
        });
    }

    public closeNotificationDialog() {
        this.dialog.closeAll();
    }

    public loadNotificationStatus(): void {
        this.octopusConnect.loadCollection('notification-status')
            .subscribe((collection: DataCollection) => {
                if (collection.entities.length > 0) {
                    this.hasNotifications = collection.entities[0].get('unreadNotifications') > 0;
                }
            });
    }

    public loadUnreadNotifications(): void {
        this.unreadNotificationsPaginated = this.octopusConnect
            .paginatedLoadCollection('notification', {
                page: 1,
                range: 6,
                orderOptions: [
                    {field: 'created', direction: OrderDirection.DESC}
                ],
                filter: {
                    // read: '0' // TODO uncomment when backend is ready
                }
            });
        this.unreadNotificationsPaginated.collectionObservable
            .pipe(
                map((collection: DataCollection) => {
                    return collection.entities;
                })
            )
            .subscribe((notifications: NotificationEntity[]) => {
                notifications = notifications.filter(notification => notification.get('read') === 0); // TODO remove when backend is ready

                this.unreadNotifications$.next(notifications);
            });
    }

    public loadReadNotifications(): void {
        this.readNotificationsPaginated = this.octopusConnect
            .paginatedLoadCollection('notification', {
                page: 1,
                range: 6,
                orderOptions: [
                    {field: 'created', direction: OrderDirection.DESC}
                ],
                filter: {
                    // read: '1' // TODO uncomment when backend is ready
                }
            });
        this.readNotificationsPaginated.collectionObservable
            .pipe(
                map((collection: DataCollection) => {
                    return collection.entities;
                })
            )
            .subscribe((notifications: NotificationEntity[]) => {
                this.readNotifications$.next(notifications);
            });
    }

    public loadNextUnreadNotifications(): void {
        if (this.unreadNotificationsPaginated.paginator.hasNextPage) {
            this.unreadNotificationsPaginated.paginator.page = this.unreadNotificationsPaginated.paginator.page + 1;
        }
    }

    public loadNextReadNotifications(): void {
        if (this.readNotificationsPaginated.paginator.hasNextPage) {
            this.readNotificationsPaginated.paginator.page += 1;
        }
    }

    /**
     * Launch the notifications request
     * @param {number} userId Current user id
     * @param {boolean} mine
     * @returns {Observable<DataEntity[]>} The notifications Observable
     */
    getUserNotifications(userId: number, mine: boolean = true): Observable<DataEntity[]> {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
            this.notificationsSubscription = null;
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('notification', {
            recipient: String(userId)
        }).pipe(map((collection: DataCollection) => {
            return collection.entities;
        }));

        if (mine) {
            this.notificationsSubscription = obs.subscribe(entities => {
                this.notifications = entities;
                this.communicationCenter.getRoom('notifications').next('myNotifications', entities);
            });
        }

        return obs;
    }

    /**
     * Sends notification to a specific user
     * @param {number} recipient Recipient user id
     * @param {string} type Type of the notification
     * @param {Object} data Data stored with the notification
     * @returns {Observable<DataEntity>} The notification observable
     */
    sendNotificationToUser(
        recipient: number,
        type: string,
        data: Object = {}
    ): Observable<DataEntity> {
        return this.octopusConnect.createEntity('notification', {
            recipient: recipient,
            type: type,
            data: JSON.stringify(data)
        });
    }

    // temporary
    sendNotif(recipient: number, type: string, data: Object = {}): Observable<DataEntity> {
        const definition: NotificationDefinition = this.getDefinition(type);

        if (definition) {
            let label: string;

            if (typeof definition.text === 'string') {
                label = definition.text;
            }

            return this.sendNotificationToUser(recipient, type, data);
        }
    }

    /**
     * Sends a notification to many users
     * @param {number[]} recipients Recipients user ids
     * @param {string} type Type of the notification
     * @param {Object} data Data stored with the notification
     * @returns {Observable<DataEntity[]>} Observables of the notifications
     */
    sendNotificationToUsers(
        recipients: number[],
        type: string,
        data: Object = {}
    ): Observable<DataEntity[]> {
        const obs: Observable<DataEntity>[] = [];
        if (!recipients) {
            recipients = [];
        }
        recipients.forEach(recipient => obs.push(this.sendNotificationToUser(recipient, type, data)));
        return combineLatest(...obs);
    }

    // temporary
    sendTestNotificationToMe(type: number): Observable<DataEntity> {
        switch (type) {
            case 1:
                return this.sendNotif(this.currentUserId, 'ACTION_ON_PROJECT', {projectId: 11});

            case 2:
                return this.sendNotif(this.currentUserId, 'DASHBOARD');

            case 3:
                return this.sendNotif(this.currentUserId, 'FILEMANAGER_NEW_FILES');
        }

    }

    /**
     * Compare two objects, key by key
     * @param {Object} object1
     * @param {Object} object2
     * @returns {boolean} true of objects are identical
     */
    private compareObjects(object1: Object, object2: Object): boolean {
        if (object1 === object2) {
            return true;
        }

        if (!(object1 instanceof Object) || !(object2 instanceof Object)) {
            return false;
        }

        if (object1.constructor !== object2.constructor) {
            return false;
        }

        for (const key in object1) {
            if (!object1.hasOwnProperty(key)) {
                continue;
            }

            if (!object2.hasOwnProperty(key)) {
                return false;
            }

            if (object1[key] === object2[key]) {
                continue;
            }

            if (typeof (object1[key]) !== 'object') {
                return false;
            }

            if (!this.compareObjects(object1[key], object2[key])) {
                return false;
            }
        }

        for (const key in object2) {
            if (object2.hasOwnProperty(key) && !object1.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Delete all the notifications with identical data
     * @param {string} type Type of the notifications to delete
     * @param {Object} data Data for comparison
     * @returns {Observable<boolean[]>} Deletion observables
     */
    deleteNotificationsByData(type: string, data: Object): Observable<boolean[]> {
        const obsList: Observable<boolean>[] = [];

        const notifsList: DataEntity[] = this.notifications.filter(entity => {
            return type === entity.get('type') && this.compareObjects(data, JSON.parse(entity.get('data')));
        });

        for (const entity of notifsList) {
            obsList.push(entity.remove());
        }

        return combineLatest(...obsList);
    }

    /**
     * Register a new type of notification
     * @param {NotificationDefinition} definition The new notification definition
     */
    registerNotification(definition: NotificationDefinition) {
        this.registeredNotificationsDefinitions[definition.type] = definition;
        this.translation.get(definition.name).subscribe(translated => definition.translatedName = translated);

        if (typeof definition.text === 'string') {
            this.translation.get(definition.text).subscribe(translated => definition.translatedText = translated);
        }
    }

    /**
     * Gets the type translated name
     * @param {string} type Notification type
     * @returns {string} The translated string
     */
    getTypeName(type: string): string {
        const definition: NotificationDefinition = this.registeredNotificationsDefinitions[type];

        if (definition) {
            return definition.translatedName;
        }

        return '';
    }

    /**
     * Gets a notification definition, by type
     * @param {string} type Notification type
     * @returns {NotificationDefinition} The type definition
     */
    getDefinition(type: string) {
        const definition: NotificationDefinition = this.registeredNotificationsDefinitions[type];

        if (!definition) {
            console.log('Unable to find notification definition: ' + type);
            return;
        }

        return definition;
    }

    /**
     * Get the translated notification text, by notification type
     * @param {string} type Notification type
     * @param {Object} data Text template data
     * @returns {string} The translated, and 'templated' text
     */
    getTranslatedText(type: string, data: Object): string {
        const definition: NotificationDefinition = this.registeredNotificationsDefinitions[type];
        let text = definition.text;

        this.translation.get(definition.text).subscribe((translation: string) => {
            text = translation;
        });

        if (definition) {
            this.translation.get(definition.translatedText).subscribe((translation: string) => {
                text = translation;

                if (definition.textTransform) {
                    const transforms: { [key: string]: string } = definition.textTransform(text, data);
                    for (const key in transforms) {
                        if (transforms.hasOwnProperty(key)) {
                            const exp: RegExp = new RegExp(`{{\\s*${ key }\\s*}}`, 'g');
                            text = text.replace(exp, transforms[key] || '');
                        }
                    }
                }
            });

        }
        return text;
    }

    /**
     * Is the notification of a registered type
     * @param {DataEntity} entity Notification DataEntity
     * @returns {boolean} True if the notification type is registered
     */
    isNotificationTypeRegistered(entity: DataEntity): boolean {
        return this.registeredNotificationsDefinitions[entity.get('type')] !== undefined;
    }

    public markAsRead(notifications: NotificationEntity[] | DataEntity[]): void {
        // TODO check if one request per notification is ok
        notifications.forEach(notification => {
            notification.set('read', 1);
            notification.save().subscribe();
        });
    }

    /**
     * Set all notification as read (for the current user)
     * @returns {Observable<DataEntity[]>} The notifications observable
     */
    setAllAsRead(): Observable<DataEntity[]> {

        const obs: Observable<DataEntity>[] = [];

        this.notifications.forEach(notif => {
            notif.set('read', true);
            obs.push(notif.save());
        });

        return combineLatest(...obs);
    }

    /**
     * Delete all notifications (for the current user)
     * @returns {Observable<boolean[]>} The deletion observable
     */
    deleteAllNotifications(): Observable<boolean[]> {

        const obs: Observable<boolean>[] = [];

        Array.from(this.notifications).forEach(notif => {
            obs.push(notif.remove());
        });

        return combineLatest(...obs);
    }

    /**
     * Route to the configured path
     * @param {DataEntity} originalEntity
     */
    doRouting(originalEntity: DataEntity) {
        const definition: NotificationDefinition = this.getDefinition(originalEntity.get('type'));

        let routing: any[];
        let val: any;

        if (definition && definition.deletedWhenRead === true) {
            originalEntity.remove();
        }

        if (definition && definition.action) {

            if (definition.action instanceof Function) {
                val = definition.action(JSON.parse(originalEntity.get('data')));
            } else {
                val = definition.action;
            }

            if (typeof val === 'string') {
                routing = [val];
            } else if (Array.isArray(val)) {
                routing = val;
            }

            if (val && routing) {
                this.router.navigate(routing);
            }
        }
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date) {
        return localizedDate(date);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date) {
        return localizedTime(date);
    }
}
