import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {Contest, ContestRegistrationEntity} from '@modules/contest/core/model/contest';
import {RegistrationDialogComponent} from '@modules/contest/core/component/registration-dialog/registration-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog';
import {ContestService} from '@modules/contest';
import {AuthenticationService} from '@modules/authentication';
import {SyncRules} from "@modules/contest/core/model/rules";
import {AuthorizationService} from "@modules/authorization";
import {DataEntity} from "octopus-connect";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
    selector: 'app-contest-card',
    templateUrl: './contest-card.component.html'
})
export class ContestCardComponent implements OnInit {
    @Input() contest: Contest;
    
    @ViewChild('shareableCodeBlock') shareableCodeBlock: MatTooltip;
    
    public isRegistered = false;

    private canRegisterInContest = false;
    private canRegisterGroupsInContest = false;
    private registrationInProgress = false;
    private canShareCodeInContest = false;
    private registrations: ContestRegistrationEntity[] = [];
    private isShareableCodeCopied = false;

    constructor(
        public dialog: MatDialog,
        private clipboard: Clipboard,
        private contestService: ContestService, 
        private authService: AuthenticationService,
        private authorization: AuthorizationService,
    ) {}

    ngOnInit() {
        this.checkRegistration();
    }
    
    public get canRegister(): boolean {
        return this.canRegisterInContest;
    }
    
    public get canRegisterGroups(): boolean {
        return this.canRegisterGroupsInContest;
    }
    
    public get isRegistering(): boolean {
        return this.registrationInProgress;
    }
    
    public get canShareCode(): boolean {
        return this.canShareCodeInContest && !!this.shareableCode;
    }

    public get shareableCode(): string {
        return this.registrations.length > 0 ? this.registrations[0].get('shareableCode') : '';
    }

    public get shareableCodeTooltip(): string {
        return this.isShareableCodeCopied ? "contest.code_copied" : "contest.copy_code";
    }
    
    public get isOpen(): boolean {
        return this.contest && this.contest.startDate.getTime() <= Date.now();
    }

    public registerToContest(): void {
        this.registrationInProgress = true;

        if (this.authorization.currentUserCan(SyncRules.RegisterInIndividualContest) || this.authorization.currentUserCan(SyncRules.RegisterInCollectiveContest)) {
            this.contestService
                .openContestRegistrationForm({
                    contest: this.contest,
                })
                .subscribe((result: boolean) => {
                    if (result) {
                        this.isRegistered = true;
                        this.checkRegistration();
                    }
                    this.registrationInProgress = false;
                });
        } else {
            // Gérer le cas où l'utilisateur ne peut pas s'inscrire
            this.registrationInProgress = false;
        }
    }
    
    public editRegistration(): void {
        if (this.canRegisterGroups) {
            this.contestService
                .openContestRegistrationForm({
                    contest: this.contest,
                    registrations: this.registrations,
                })
                .subscribe((result: boolean) => {
                    if (result) {
                        this.checkRegistration();
                    }
                });
        }
    }
    
    public copyShareableCode(): void {
        this.isShareableCodeCopied = this.clipboard.copy(this.shareableCode);
        this.shareableCodeBlock.show();
        this.shareableCodeBlock.hide(3000);
    }
    
    public onEnterShareableCode(): void {
        this.isShareableCodeCopied = false;
        this.shareableCodeBlock.show();
    }
    
    public onLeaveShareableCode(): void {
        if (!this.isShareableCodeCopied) {
            this.shareableCodeBlock.hide();
        }
    }

    private checkRegistration() {
        this.canRegisterInContest = this.authorization.currentUserCan(SyncRules.RegisterInContest);
        this.canRegisterGroupsInContest = this.authorization.currentUserCan(SyncRules.RegisterGroupsInContest);
        this.canShareCodeInContest = this.authorization.currentUserCan(SyncRules.ShareCodeInContest);
        
        this.contestService.getUserRegistrations(this.contest.id).subscribe(registrations => {
            if (registrations.length) {
                this.isRegistered = true;
                this.registrations = registrations;
            }
        });
    }

    public userHasRoleForContest(): boolean {
        if (this.contest.collective) {
            return this.authorization.currentUserCan(SyncRules.RegisterInCollectiveContest);
        } else  {
            return this.authorization.currentUserCan(SyncRules.RegisterInIndividualContest);
        }
        return false; // Par défaut, ne pas autoriser
    }
}
