import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {capitalize} from 'lodash-es';

declare var tarteaucitron: any;
declare global {
    interface Window {
        tarteaucitronForceLanguage: string;
        opera: string;
        _paq: any;
    }
}

import {filter, take, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, AfterViewInit, Component, inject, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {
    LangChangeEvent,
    TranslateService,
} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {navigation} from 'app/navigation/navigation';
import {LanguageService} from 'shared/language.service';
import {IsAllowedBySettings} from './guards/is-allowed-by-settings.class';
import {ServiceInstantiator} from './service-instantiator.class';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {BrowserTestService} from '@modules/browser-test';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

import {
    brand,
    defaultMetaDescription,
    brandTitle,
    defaultLanguage,
    langs,
    GATracking,
    trackingIDFromSettings,
    matomoTrackingIDFromSettings,
    matomoTracking,
    tarteaucitronConf,
    mobileUrlRedirection,
    youTubeTracking,
    twitterTracking,
    isMobileAlert,
    customLayoutConfig,
    accessibiActivate,
    GAdsTracking,
    GTMTracking,
    MetaPixelTracking,
    SnapchatTracking,
    GAdsTrackingIDFromSettings,
    GTMTrackingIDFromSettings,
    MetaPixelTrackingIDFromSettings,
    SnapchatTrackingIDFromSettings,
} from './settings';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService, UserDataEntity} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {AccountManagementProviderService} from '@modules/account-management';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2';
import {Angulartics2Matomo} from 'angulartics2';
import {MobileRedirectionModalComponent} from '../@fuse/components/mobile-redirection-modal/mobile-redirection-modal.component';
import {LayoutConfig, FuseNavigation} from 'fuse-core/types';
import {timer} from 'rxjs/internal/observable/timer';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    lang: string;
    browserLang: string;
    themeColor = 'red';
    layoutConfig: LayoutConfig;
    navigation: FuseNavigation[];
    private tarteaucitronConf: object = tarteaucitronConf;
    public brand: string = brand;
    private brandTitle: string = brandTitle;
    private defaultMetaDescription: any = defaultMetaDescription;
    private trackingID: string = trackingIDFromSettings;
    private matomoTrackingID: string = matomoTrackingIDFromSettings;
    private GAdsTrackingID: string = GAdsTrackingIDFromSettings;
    private GTMTrackingID: string = GTMTrackingIDFromSettings;
    private MetaPixelTrackingID: string = MetaPixelTrackingIDFromSettings;
    private SnapchatTrackingID: string = SnapchatTrackingIDFromSettings;
    private GATracking: boolean = GATracking;
    private matomoTracking: boolean = matomoTracking;
    private youTubeTracking: boolean = youTubeTracking;
    private twitterTracking: boolean = twitterTracking;
    private GAdsTracking: boolean = GAdsTracking;
    private GTMTracking: boolean = GTMTracking;
    private MetaPixelTracking: boolean = MetaPixelTracking;
    private SnapchatTracking: boolean = SnapchatTracking;
    private accessibi = accessibiActivate;

    public isSnackbarRecallButtonConfigActive = false;
    private _unsubscribeAll: Subject<void>;
    public isAllowedSkipNavigation = false;

    private languageService = inject(LanguageService);
    private route = inject(ActivatedRoute);
    private isRouteAccessible = inject(IsAllowedBySettings);
    private trackingInitialized: boolean = false;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private dialog: MatDialog,
        private _layoutConfigService: LayoutConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private translationLoader: TralaTranslationLoaderService,
        private serviceInstantiator: ServiceInstantiator,
        private browserTestService: BrowserTestService,
        private dynamicNavigation: DynamicNavigationService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private location: Location,
        private connector: OctopusConnectService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private snackbarNews: SnackbarNewsService,
        private usersProvider: AccountManagementProviderService,
        private titleService: Title,
        private angularticsGA: Angulartics2GoogleGlobalSiteTag,
        private angularticsMatomo: Angulartics2Matomo,
        private renderer: Renderer2,
        private meta: Meta,
    ) {
        this._unsubscribeAll = new Subject<void>();
        this.initNavigation();
        this.initLangManagement();
        this.initMobilePlatformManagement();
        this.initLayoutConfigManagement();
        browserTestService.checkCompatibility();
        this.loadAccessiBe();
        // add default meta description if exists
        if (this.defaultMetaDescription && this.defaultMetaDescription[this._translateService.currentLang]) {
            this.meta.addTag({
                name: 'description',
                content: this.defaultMetaDescription[this._translateService.currentLang]
            });
        }

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data) => {
                if (data) {
                    !this.trackingInitialized ? this.initTracking() : null;
                    this.postAuthentication();
                } else {
                    !this.trackingInitialized ? this.initTracking() : null;
                    this.postLogout();
                }
            });
    }

    private initLayoutConfigManagement(): void {
        if (customLayoutConfig.hasOwnProperty('default')) {
            if (customLayoutConfig.hasOwnProperty(this.authService.accessLevel)) {
                this._layoutConfigService.setConfig(customLayoutConfig[this.authService.accessLevel], {emitEvent: true});
            } else {
                this._layoutConfigService.setConfig(customLayoutConfig['default'], {emitEvent: true});
            }

            this.communicationCenter
                .getRoom('authentication')
                .getSubject('userData')
                .subscribe(userData => {
                    const role = this.authService.accessLevel;
                    if (customLayoutConfig.hasOwnProperty(role)) {
                        this._layoutConfigService.setConfig(customLayoutConfig[role], {emitEvent: true});
                    } else {
                        this._layoutConfigService.setConfig(customLayoutConfig['default'], {emitEvent: true});
                    }
                });
        }
    }

    ngOnInit(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                // if in whitelist array and is not authenticated
                if (!this.authService.isAuthenticated) {
                    const whiteListUrls = ['login', 'register', 'forgot-password'];
                    for (let i = 0; i < whiteListUrls.length; i++) {
                        if (window.location.href.indexOf(whiteListUrls[i]) > -1) {
                            !this.trackingInitialized ? this.initTracking() : null;
                        }
                    }
                }
            });

        this.document.body.classList.add(this.brand);
        this.document.querySelector<HTMLLinkElement>('link[ rel *= "icon"]').href =
            'assets/' + this.brand + '/favicon.png?v=6';
        this.setTitle(this.brandTitle);
        // Subscribe to config changes
        this._layoutConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.layoutConfig = config;

                if (this.layoutConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }
            });

        // Check (prepare data) if user is Logged or Anonymous
        this.connector
            .authenticated('http')
            .pipe(take(1))
            .subscribe(
                (userData: UserDataEntity) => {
                    this.authService.loggedUser = userData;
                    this.authService.isAuthenticated = true;
                    // redirect to default when page load
                    // this.authService.overrideDefaultRouteInRegardOfRole();

                    this.communicationCenter
                        .getRoom('authentication')
                        .next('userData', userData);
                },
                (err) => {
                    if (err === 401) {
                        this.connector.logout('http').subscribe(() => {
                            this.communicationCenter
                                .getRoom('authentication')
                                .next('doLogout', null);
                        });
                    }
                }
            );
    }

    ngAfterViewInit(): void {
    }

    ngAfterViewChecked(): void {
        this.closeMenuButtonListener();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    loadGoogleAnalytics(trackingID: string): void {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute(
            'src',
            `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
        );

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\', {'anonymize_ip': true, 'cookie_expires': 33696000});`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }

    loadMatomo(trackingID: string): void {
        const matomoScript = document.createElement('script');
        matomoScript.setAttribute('type', `text/javascript`);
        matomoScript.innerText = `var _paq = window._paq || [];_paq.push([\'enableLinkTracking\']);(function() {var u=\'https://matomo.tralalere.com/\';_paq.push([\'setTrackerUrl\', u+\'matomo.php\']);_paq.push([\'setSiteId\', \'${trackingID}\']);var d=document, g=d.createElement(\'script\'), s=d.getElementsByTagName(\'script\')[0];g.type=\'text/javascript\'; g.async=true; g.defer=true; g.src=u+\'matomo.js\'; s.parentNode.insertBefore(g,s);})();`;
        document.documentElement.firstChild.appendChild(matomoScript);
    }

    /**
     * insert script to evaluate accessibility
     */
    private loadAccessiBe(): void {
        if (this.accessibi) {
            const s = document.createElement('script');
            s.setAttribute('type', `text/javascript`);
            s.innerText = `(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, triggerRadius : '20' } }); }; h.appendChild(s); })();`;
            document.documentElement.firstChild.appendChild(s);
        }

    }


    loadTartaucitron(): HTMLScriptElement {
        const tarteaucitronScript = document.createElement('script');
        tarteaucitronScript.setAttribute(
            'src',
            `https://www.tralalere.com/header/tarteaucitron/gcmv2/tarteaucitron.min.js`
        );
        this.renderer.appendChild(document.body, tarteaucitronScript);
        return tarteaucitronScript;
    }

    tarteaucitronInit(): void {
        window.tarteaucitronForceLanguage = this._translateService.currentLang;
        tarteaucitron.init(this.tarteaucitronConf);
        // Google analytics
        if (this.GATracking) {
            tarteaucitron.user.gtagUa = this.trackingID;
            // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
            tarteaucitron.user.gtagMore = function (): void {
                /* add here your optionnal gtag() */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
        }

        // matomo
        /* // remove from tarteaucitron to force matomo tracking
        if (this.matomoTracking) {
            tarteaucitron.user.matomoId = this.matomoTrackingID;
            tarteaucitron.user.matomoHost = 'https://matomo.tralalere.com/';
            (tarteaucitron.job = tarteaucitron.job || []).push('matomo');
        } */

        // youtube
        if (this.youTubeTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
        }

        // twitter
        if (this.twitterTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('twitterembed');
        }

        // GAds
        if (this.GAdsTracking) {
            tarteaucitron.user.googleadsId = this.GAdsTrackingID;
            (tarteaucitron.job = tarteaucitron.job || []).push('googleads');
        }

        // GTM
        if (this.GTMTracking) {
            tarteaucitron.user.googletagmanagerId = this.GTMTrackingID;
            (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');
        }

        // Meta Pixel
        if (this.MetaPixelTracking) {
            tarteaucitron.user.facebookpixelId = this.MetaPixelTrackingID;
            tarteaucitron.user.facebookpixelMore = function () { /* add here your optionnal facebook pixel function */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');
        }

        // Snapchat
        if (this.SnapchatTracking) {
            tarteaucitron.user.snapchatId = this.SnapchatTrackingID;
            tarteaucitron.user.snapchatEmail = '__INSERT_USER_EMAIL__';
            (tarteaucitron.job = tarteaucitron.job || []).push('snapchat');
        }

        //GCMv2
        if (this.GTMTracking || this.GAdsTracking || this.GATracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmadstorage');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmanalyticsstorage');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmfunctionality');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmpersonalization');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmadsuserdata');
            (tarteaucitron.job = tarteaucitron.job || []).push('gcmsecurity');
        }

        this.trackingInitialized = true;
    }

    private initMobilePlatformManagement(): void {
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
    }

    private initTracking(): void {

        // cookies managment with tarteaucitron
        if ((this.youTubeTracking
                || this.twitterTracking
                || this.GATracking
                || this.GAdsTracking
                || this.GTMTracking
                || this.MetaPixelTracking
                || this.SnapchatTracking)
            && !this.authService.isGAR()
        ) {
            this.loadTartaucitron().onload = () => {
                this.tarteaucitronInit();
            };
        }

        // Matomo tracking
        if (this.matomoTracking) {
            this.loadMatomo(this.matomoTrackingID);
            this.angularticsMatomo.startTracking();
        }

        // Google Analytics tracking
        if (this.GATracking) {
            this.angularticsGA.startTracking();
        }
    }

    private initLangManagement(): void {
        if (brand.toString() === 'amazon_python') {
            const defaultLang: string[] = langs.map((lang) => lang.id);
            this.browserLang =
                defaultLang.indexOf(navigator.language.substring(0, 2)) !== -1
                    ? navigator.language.substring(0, 2)
                    : defaultLanguage;

            // Add languages
            this._translateService.addLangs(defaultLang);

            // Set the default language
            this._translateService.setDefaultLang(this.browserLang);

            // Use a language
            this.lang = localStorage.getItem('lang') || this.browserLang;
            this.document.documentElement.lang = this.lang;
            this.connector.setLanguage(this.lang);
            this._translateService.use(this.lang);
            // Set the navigation translations
            this.translationLoader.loadTranslations(...defaultLang);
        } else {
            const lang = this.languageService.getActiveLanguage().id;
            this.document.documentElement.lang = lang;
            this.connector.setLanguage(lang);
            // Set the navigation translations
            this.translationLoader.loadTranslations(...this.languageService.availableLanguages.map((lang) => lang.id));
        }

        this._translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.connector.setLanguage(event.lang);
                this.document.documentElement.lang = event.lang;
                this._translateService.currentLang = event.lang;
                // init tarteaucitron and update tarteaucitron language
                if (
                    this.youTubeTracking ||
                    this.twitterTracking ||
                    this.GATracking
                ) {
                    this.tarteaucitronInit();
                }
            }
        );

        if (brand.toString() === 'amazon_python') {
            // nothing
        } else {
            this.checkIfQueryParamsForceLanguage();
            this.languageService.onLanguageChange$.subscribe((lang) => {
                // Update dynamic menu with new language
                this.dynamicNavigation.generateMenu(this.dynamicNavigation.currentMenuId, true);
                // check route with access settings for new language
                this.isRouteAccessible.checkRouteAllowedOrRedirect(this.route.snapshot, this.router.routerState.snapshot);
            });
        }
    }

    private initNavigation(): void {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', []);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const slugs = event.url.split('/').slice(1);

                const title = slugs
                    .map((slug) => `${capitalize(slug.split('?')[0])}`)
                    .join(' - ');
                this.setTitle(`${this.brandTitle} | ${title}`);
                this.isAllowedSkipNavigation = !(event.url.includes('register') || event.url.includes('login') || event.url.includes('password'));
            });
    }

    private postAuthentication(): void {
        this.isSnackbarRecallButtonConfigActive = this.snackbarNews.isRecallButtonActive;

        timer(1000).subscribe(() => {
            // isMobileAlert is used to show a msg mobile not supported but without redirection
            if (this.isMobile() && (mobileUrlRedirection || isMobileAlert)) {
                const dialogRef = this.dialog.open(
                    MobileRedirectionModalComponent,
                    {}
                );
                dialogRef.afterClosed().subscribe((result) => {
                    // isMobileAlert => no redirection only alert
                    if (result && !isMobileAlert) {
                        window.location.href = mobileUrlRedirection;
                    }
                });
            }
        });
    }

    private postLogout(): void {
        this.isSnackbarRecallButtonConfigActive = false;
    }

    /**
     * know if device is mobile..
     * @private
     */
    private isMobile(): boolean {
        let check = false;
        (function (a: string): void {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    navigateToAnchor(event: Event) {
        event.preventDefault();
        const anchorId = 'maincontent';
        const element = document.getElementById(anchorId);

        if (element) {
            element.focus();
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    /**
     * use for stay on menu even if there is some index on main content
     * when we press tab
     */
    closeMenuButtonListener() {
        const buttonElement = document.getElementById('togglemenutabgestion');
        if (buttonElement) {
            buttonElement.addEventListener('keydown', this.handleKeyDown.bind(this));
        }
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab' && event.target instanceof Element && event.target.id === 'togglemenutabgestion') {
            // La touche Tab est pressée sur le bouton fermer le menu on va aller dans le menu et pas dans la page principal
            event.preventDefault();
            const element = document.getElementById('mainmenuelem');
            const firstFocusableElement = element.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
            if (firstFocusableElement && firstFocusableElement instanceof HTMLElement) {
                // first focusable element
                firstFocusableElement.focus();
            }
        }
    }

    private checkIfQueryParamsForceLanguage(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const queryParams = this.router.parseUrl(this.router.url).queryParams;
            const suggestedLanguageId = queryParams && queryParams['SuggestedLanguage'];

            // if there is no our query param, we don't need to do anything
            if (!suggestedLanguageId) {
                return;
            }
            const suggestedLanguage = this.languageService.availableLanguages.find(lang => lang.id === suggestedLanguageId);

            // if the language is already set, we don't need to do anything
            if (this.languageService.getActiveLanguage() === suggestedLanguage) {
                return;
            }
            this.languageService.setActiveLanguage(suggestedLanguage);

            // and now we remove the suggested language from the query params
            this.router.navigate([], {
                queryParams: {...queryParams, SuggestedLanguage: null},
                queryParamsHandling: 'merge'
            });
        });
    }
}
