import {AfterViewInit, Component, ElementRef, Input, QueryList, Renderer2, ViewChildren} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {filter, take} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {brand} from '../../../app/settings';

/**
 * composant autonome il gère son état visible ou pas ses setting etc...
 * ne sert que pour amazon python
 * Il fournit l'info de l'élèment selectionné via la communication center:
 * this.communicationCenter.getRoom('root-filter').getSubject('selected')
 */
@Component({
    selector: 'app-root-filter',
    templateUrl: './root-filter.component.html',
})

// pas mettre partout dépend si on est prof ou éléve et de si c'est amazon python ou pas

export class RootFilterComponent implements AfterViewInit {
    @ViewChildren('labelContainer') labels!: QueryList<ElementRef>;
    @ViewChildren('inputContainer') inputs!: QueryList<ElementRef>;

    @Input() selectedOption: number = -1;
    private currentUserId = -1;
    public filterState = filterState;
    public isAllowed = false;
    brand: string = brand;
    // route ou le composant est utilisé selon le profil
    routes = [{
        role: 6, routesAllowed: ['achievement/my-profile', 'achievement/my-city', 'followed/tab', 'lessons/list/models'] // learner
    },
        {role: 5, routesAllowed: ['lessons/list/models', 'followed/tab']}, // trainer
    ];

    constructor(private communicationCenter: CommunicationCenterService,
                private router: Router,
                private renderer: Renderer2) {
        //récupération du profil utilisateur
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(user => {
            //on écoute les routes pour vérifier à chaque changement si le composant est authorisé
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => {
                    this.isAllowed = user && this.isRouteAllowedForRole(user.get('role'), this.router.url);
                });

            this.currentUserId = user?.id;

            let isData = false;
            // récupération des donné choisis précèdemment si il y a eu un choix
            if (localStorage.getItem('root-filter-choice') !== undefined && localStorage.getItem('root-filter-choice') !== null) {
                let data = JSON.parse(localStorage.getItem('root-filter-choice'));
                isData = data.filter(d => d.userId === this.currentUserId).length > 0;
            }
            // si on a jamais chargé le composant avant il faut que le communication center ait une valeur par défaut
            if (this.selectedOption === -1 && !isData) {
                this.selectedOption = filterState.python;
                this.communicationCenter.getRoom('root-filter').getSubject('selected').next(filterState.python);
            } else {
                const data = JSON.parse(localStorage.getItem('root-filter-choice'));
                this.selectedOption = data.filter(d => d.userId === this.currentUserId)[0].selectedOption;
                this.communicationCenter.getRoom('root-filter').getSubject('selected').next(this.selectedOption);
            }
        });

        // on écoute les event de changement et on met à jour le filtre avec la valeur
        // utile que si on a un plusieurs exemplaire du composant
        this.communicationCenter
            .getRoom('root-filter')
            .getSubject('selected')
            .pipe(take(1))
            .subscribe(res => {
                this.selectedOption = res;
            });

    }


    ngAfterViewInit(): void {
        // accessibility manage nav
        this.labels.forEach((label, index) => {
            const input = this.inputs.toArray()[index];
            this.renderer.listen(label.nativeElement, 'focus', () => {
                input.nativeElement.focus();
            });
            this.renderer.listen(label.nativeElement, 'click', () => {
                input.nativeElement.focus();
            });
        });
    }

    public update(): void {
        const data = JSON.parse(localStorage.getItem('root-filter-choice'));
        if (data && data !== undefined && data !== null && data.length > 0) {
            const d = data.filter(d => d.userId === this.currentUserId)[0];
            if (d) {
                //current user as data
                d.selectedOption = this.selectedOption;
            } else {
                //current user has no data but data exist ad another line
                data.push({userId: this.currentUserId, selectedOption: this.selectedOption});
            }

            localStorage.setItem('root-filter-choice', JSON.stringify(data));
        } else {
            localStorage.setItem('root-filter-choice', JSON.stringify([{userId: this.currentUserId, selectedOption: this.selectedOption}]));
        }

        this.communicationCenter.getRoom('root-filter').getSubject('selected').next(this.selectedOption);
    }

    private isRouteAllowedForRole(roles: number[], currentURL: string) {
        let allowedRoutes = this.routes.filter(route => roles.includes(route.role)).map(route => route.routesAllowed).flat() || [];
        return allowedRoutes.some(route => currentURL.includes(route));
    }

    selectOption(value: number): void {
        this.selectedOption = +value;
        this.update();
    }
}

export enum filterState {
    junior, // concept : "4160 en preprod set par interface term : generic.id_junior"
    explorer, // concept : "4161 generic.id_college" college devient explorer
    python // concept : // "4162 generic.id_python"
}
