import {Component, inject, OnInit} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {take, takeUntil, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {DataEntity} from 'octopus-connect';
import {combineLatest} from 'rxjs';
import {WarningComponent} from 'fuse-core/components/compass/warning/warning.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-compass-resources',
    templateUrl: './compass-resources.component.html',
})
export class CompassResourcesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    public dialog = inject(MatDialog);
    public displayLoader: boolean = true;
    public resources: DataEntity[] = [];
    public queryParams: Params = {};
    public title = '';
    public bannerText = '';
    public goBackLinkName = 'compass.home';
    public medias: {
        alt: string,
        filemime: string,
        filename: string,
        title: string,
        url: string,
    }[] = [];
    constructor() {
        super();
        const navigation = this.router.getCurrentNavigation();
        if (navigation?.extras?.state) {
            if (navigation.extras.state.title) {
                this.goBackLinkName = navigation.extras.state.title;
            }
            this.title = navigation?.extras?.state.selectedThemeTitle;
            this.bannerText = navigation?.extras?.state.selectedThemeBody;
            this.medias = navigation?.extras?.state.medias;
            this.queryParams = navigation.extras.state;
            if (navigation?.extras?.state.id) {
                this.initialize(navigation?.extras?.state.id);
            }
        } else {
            this.router.navigate(['boussole']);
        }
    }

    ngOnInit() {
    }

    private initialize(ids?: string[]): void {
        const filterOptions = ids && ids.length ? {filter: {id: ids}} : {};
        const resources$ = this.compassService.loadResources(filterOptions)
            .pipe(
                take(1),
            );

        combineLatest([resources$])
            .pipe(
                take(1),
                tap(([resources]) => this.resources = resources),
                tap(() => this.displayLoader = false)
            )
            .subscribe();
    }

    seeResource(resource: DataEntity) {
        if (resource.get('description')) {
            this.router.navigate(['boussole-ressource'], {state: _.merge(this.queryParams, {idResource: resource.id})})
        } else if (resource.get('link')) {
            const dialogRef = this.dialog.open(WarningComponent);
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    window.open(resource.get('link'), '_blank');
                }
            });
        }
    }

    goBack() {
        // TODO n'utilisons pas le state. il faudrait que l'url soit du genre /boussole-ressources/:id ou /boussole-explore/:exploreEntityId/:id
        this.router.navigate(['boussole-themes', this.queryParams.exploreEntityId])
        // si on a pas d'exploreEntityId il nous ramène sur la page par defaut : /boussole
    }

    // slug function convert to lowercase and replace space with - and no special characters
    public slugify(text: string): string {
        let dict = { 'à':'a', 'á':'a', 'é':'e', 'è':'e', 'ê':'e', 'ç':'c', 'ë':'e', 'ï':'i', 'î':'i', 'ô':'o', 'ö':'o', 'ù':'u', 'û':'u', 'ü':'u', 'ÿ':'y', 'ñ':'n' };
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w ]/g, function(char) {
                return dict[char] || char;
            })
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    public getResourceType(resource: DataEntity, useSlug?: boolean): string {
        if (resource.get('type') && resource.get('type')[0] && resource.get('type')[0]['name']) {
            if (useSlug) {
                return this.slugify(resource.get('type')[0]['name']);
            }
            return resource.get('type')[0]['name'];
        }
        return '';
    }
}
