import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appMoveFocusToNextAllowedElement]'
})

/**
 * use to simulate the tab click and go to the next allowed and valid element when the tab event come on the html element with the directive
 * previousTarget is use when user do shift tab to come back on previous point we need to say where he has to go
 */
export class MoveFocusToNextAllowedElementDirective {
    @Input() previousTarget: string | null = null;
    @Input() isEnabled: boolean = true;// to disable or enable the directive

    constructor(private elementRef: ElementRef) {
    }

    private reverse = false;
    private previousFocusedElement: EventTarget | null = null;
    private isTabKeyPressed: boolean = false; // is tab key was pressed to go on element

    @HostListener('focus', ['$event'])
    onFocus(event: FocusEvent) {
        if (!this.isEnabled || !this.isTabKeyPressed) {
            return;
        }
        if (!this.reverse) {
                this.simulateTab();
        } else {
            if (this.previousTarget) {
                const target = document.getElementById(this.previousTarget);
                if (target) {
                    target.focus();
                }
            }
            this.reverse = false;
        }
        this.isTabKeyPressed = false;
    }

    // reverse tab on reverse case go to first allowed parent after one with the directive
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            this.isTabKeyPressed = true; // mark tab key as pressed only event allowed to launch directive focus
        }

        if (event.key === 'Tab' && event?.shiftKey && this.previousFocusedElement === event.target) {
            this.reverse = true;
        } else if (event.key === 'Tab' && !event?.shiftKey) {
            this.reverse = false;
        }
    }

    simulateTab() {
        const focusableElements = this.elementRef.nativeElement.querySelectorAll('MAT-SELECT,input, button, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = this.findFirstFocusableElement(focusableElements);
        this.previousFocusedElement = firstFocusableElement;
        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }

    findFirstFocusableElement(elements: NodeListOf<HTMLElement>): HTMLElement {
        const elementArray = Array.from(elements);

        // order tab index
        elementArray.sort((a, b) => {
            const tabindexA = parseInt(a.getAttribute('tabindex') || '0');
            const tabindexB = parseInt(b.getAttribute('tabindex') || '0');
            return tabindexA - tabindexB;
        });

        for (let i = 0; i < elementArray.length; i++) {
            const element = elementArray[i];
            if (this.isFocusable(element)) {
                return element;
            }
            const childFocusableElement = element.querySelector('input, button, select, textarea, [tabindex]:not([tabindex="-1"]), mat-select');
            if (childFocusableElement) {
                return this.findFirstFocusableElement(element.querySelectorAll('input, button, select, textarea, [tabindex]:not([tabindex="-1"]), mat-select'));
            }
        }
        return null;
    }

    isFocusable(element: HTMLElement): boolean {
        return (
            element.tabIndex >= 0 &&
            !element.hidden &&
            !element.getAttribute('aria-hidden') &&
            (element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0)
        );
    }
}
