import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CompassComponent} from './home/compass.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedMaterialModule} from 'shared/shared-material.module';
import {
    CompassResourcesComponent
} from './resources/compass-resources/compass-resources.component';
import {CompassThemesComponent} from 'fuse-core/components/compass/compass-themes/compass-themes.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatIconModule} from '@angular/material/icon';
import { CompassResourceComponent } from './resources/compass-resource/compass-resource.component';
import { KeywordsComponent } from './ui/keywords/keywords.component';
import { CompassCardComponent } from './ui/compass-card/compass-card.component';
import {TranslateModule} from "@ngx-translate/core";
import {RandomClassDirective} from "fuse-core/directives/random-class/random-class.directive";
import { WarningComponent } from './warning/warning.component';
import {MatLegacyChip} from '@angular/material/legacy-chips';
import { ModalWrapperMediaComponent } from './resources/modal-wrapper-media/modal-wrapper-media.component';
import {CompassService} from 'fuse-core/services/compass.service';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {IsUserLogged} from '../../../app/guards';

const routes: Routes = [
    {
        path     : 'boussole',
        component: CompassComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole-ressources',
        component: CompassResourcesComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole-ressource',
        component: CompassResourceComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole-themes/:id',
        component: CompassThemesComponent,
        canActivate: [IsUserLogged],
    }
];

@NgModule({
    declarations: [
        CompassComponent,
        CompassResourcesComponent,
        CompassThemesComponent,
        CompassResourceComponent,
        CompassResourceComponent,
        KeywordsComponent,
        CompassCardComponent,
        WarningComponent,
        ModalWrapperMediaComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        TranslateModule,
        RandomClassDirective,
        FuseSharedModule
    ],
    providers: [MatLegacyChip]
})
export class CompassModule {
    static forRoot(): ModuleWithProviders<CompassModule> {
        return {
            ngModule: CompassModule,
            providers: [
                CompassService,
            ]
        };
    }
}
