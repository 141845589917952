export const authentication = {
    displayLoginLogo: true,
    enableSSO: false,
    enableLocalCache: true,
    floatLabelControl: 'always', // 'always' | 'never' | 'auto'
    firstConnexionRedirection: {
        default: '/achievement',
        trainer: '/home',
    },
    overrideDefaultRouteByRole: {
        learner: '/achievement',
        trainer: 'lessons/list/models',
    },
    activeChangePasswordStrategy: true,
    onlyLoginWithMail: false,
    displayGARHelper: true,
    enableGAR: true,
    urlGarRegister: 'https://www.citizencode.net/inscription-gar/',
};
